exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-adult-swimming-lessons-tsx": () => import("./../../../src/pages/adult-swimming-lessons.tsx" /* webpackChunkName: "component---src-pages-adult-swimming-lessons-tsx" */),
  "component---src-pages-baby-swimming-aquanatal-yoga-classes-tsx": () => import("./../../../src/pages/baby-swimming/aquanatal-yoga-classes.tsx" /* webpackChunkName: "component---src-pages-baby-swimming-aquanatal-yoga-classes-tsx" */),
  "component---src-pages-baby-swimming-index-tsx": () => import("./../../../src/pages/baby-swimming/index.tsx" /* webpackChunkName: "component---src-pages-baby-swimming-index-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-closure-holiday-schedule-tsx": () => import("./../../../src/pages/closure-holiday-schedule.tsx" /* webpackChunkName: "component---src-pages-closure-holiday-schedule-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dolphin-blog-tsx": () => import("./../../../src/pages/dolphin-blog.tsx" /* webpackChunkName: "component---src-pages-dolphin-blog-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-group-swimming-lessons-tsx": () => import("./../../../src/pages/group-swimming-lessons.tsx" /* webpackChunkName: "component---src-pages-group-swimming-lessons-tsx" */),
  "component---src-pages-hiring-tsx": () => import("./../../../src/pages/hiring.tsx" /* webpackChunkName: "component---src-pages-hiring-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-mermaid-swimming-tsx": () => import("./../../../src/pages/mermaid-swimming.tsx" /* webpackChunkName: "component---src-pages-mermaid-swimming-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-private-swimming-lessons-tsx": () => import("./../../../src/pages/private-swimming-lessons.tsx" /* webpackChunkName: "component---src-pages-private-swimming-lessons-tsx" */),
  "component---src-pages-safeguarding-children-and-adults-tsx": () => import("./../../../src/pages/safeguarding-children-and-adults.tsx" /* webpackChunkName: "component---src-pages-safeguarding-children-and-adults-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

