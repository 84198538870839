// Import custom CSS styles
import './src/css/bootstrap.css';
import './src/css/style.css';
// Fonts
import '@fontsource/cherry-bomb-one';
import '@fontsource-variable/nunito';
import '@fontsource/shadows-into-light';

import { GatsbyBrowser } from 'gatsby';

// Ensures the page is scrolled to the top when navigating between pages
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition?.(location);
  setTimeout(() => window.scrollTo(...(currentPosition ?? [0, 0])), 100);

  return false;
};
